<script setup lang="ts">
import dayjs from 'dayjs';

const props = withDefaults(
  defineProps<{
    label?: string;
    format?: string;
    disabled?: boolean;
    rules?: any;
    placeholder?: string;
    maxDate?: Date;
    minDate?: Date;
    errorMessages?: any;
    hideDetails?: boolean | string;
    appendIcon?: string;
    closeOnSelect?: boolean;
  }>(),
  {
    label: 'Date',
    format: 'DD/MM/YYYY',
    disabled: false,
    rules: [],
    errorMessages: '',
    placeholder: 'DD/MM/YYYY',
    minDate: undefined,
    maxDate: undefined,
    hideDetails: 'auto',
    appendIcon: 'mdi-calendar',
    closeOnSelect: true,
  }
);

const menu = ref(false);

const date = defineModel<string>({
  default: '',
});

const dateFormatted = computed(() => {
  return date.value ? dayjs(date.value).format(props.format) : '';
});

const onUpdate = (): void => {
  if (props.closeOnSelect) {
    menu.value = false;
  }
};
</script>
<template>
  <div>
    <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y>
      <template #activator="{ props }">
        <v-text-field
          :label="label"
          :placeholder="placeholder"
          :input-placeholder="placeholder"
          :disabled="disabled"
          :hide-actions="true"
          :rules="rules"
          :hide-details="hideDetails"
          variant="solo-filled"
          :readonly="true"
          :model-value="dateFormatted"
          :append-inner-icon="appendIcon"
          v-bind="props"
          :error-messages="errorMessages"
          flat
        />
      </template>
      <v-date-picker
        v-model="date"
        :input-placeholder="placeholder"
        input-mode="calendar"
        :min="minDate"
        :max="maxDate"
        :format="format"
        @update:modelValue="onUpdate"
        @click:cancel="menu = false"
        @click:save="menu = false"
      />
    </v-menu>
  </div>
</template>
